import { Flex, Text } from "@chakra-ui/react";
import { NoAlerts } from "./NoAlerts";

export const AlertsHome = () => {
  return (
    <Flex direction="column" minW="135vh">
      <Flex
        justify="space-between"
        align="center"
        p={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          Alerts
        </Text>
      </Flex>
      <Flex p={4} justify="space-between" align="right" direction="row">
        <NoAlerts />
      </Flex>
    </Flex>
  );
};
