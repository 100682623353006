import { Flex } from "@chakra-ui/react";
import { BotsDashboard } from "../components/MyBots/BotsDashboard";
import { SideBar } from "../components/Sidebar/SideBar";

export const Home = () => {
  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar />
      </Flex>
      <Flex flex="4" justify="center" direction="column" align="center">
        <BotsDashboard />
      </Flex>
    </Flex>
  );
};
