import { Flex } from "@chakra-ui/react";
import { MyBotsHome } from "./MyBotsHome";
export const BotsDashboard = () => {
  return (
    <Flex direction="column" minH="100vh" minW="150vh" align="flex-start">
      <Flex flex="1">
        <MyBotsHome />
      </Flex>
    </Flex>
  );
};
