import { chakra, HTMLChakraProps } from "@chakra-ui/react";

export const Logo = (props: HTMLChakraProps<"img">) => (
  <chakra.img
    src={process.env.PUBLIC_URL + "/assets/chatworks-logo.png"}
    alt="Logo"
    {...props}
    height="18%"
    width="18%"
  />
);
