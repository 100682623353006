export const sources = [
  {
    id: "1",
    name: "Production website",
    handle: "wordpress",
    status: "active",
    avatarUrl:
      "https://upload.wikimedia.org/wikipedia/commons/9/98/WordPress_blue_logo.svg",
    lastSync: "Wordpress",
    currentCost: "$0.59",
  },
  {
    id: "2",
    name: "Data Warehouse",
    handle: "snowflake",
    avatarUrl:
      "https://logos-world.net/wp-content/uploads/2022/11/Snowflake-Symbol.png",
    lastSync: "Snowflake",
    status: "active",
    currentCost: "$7.68",
  },
  {
    id: "3",
    name: "Marketing CRM",
    handle: "Hubspot",
    status: "disabled",
    avatarUrl:
      "https://companieslogo.com/img/orig/HUBS-3bd277ce.png?t=1720244492",
    lastSync: "Hubspot",
    currentCost: "$12.34",
  },
];
