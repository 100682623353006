import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";

export const useUpdateBot = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const updateBot = async (botId: string, newBotName: string) => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem("access_token") || "";
      const response = await fetch(`${API_URL}/api/v1/bots/bots/${botId}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify({
          name: newBotName,
          // TODO add the S3 URL here
          avatar: "",
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update bot name");
      }

      toast({
        title: "Bot updated",
        description: `The bot name has been updated successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to update bot",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { updateBot, loading, error };
};
