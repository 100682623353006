import { Flex, Heading, VStack, Divider } from "@chakra-ui/react";
import { Profile } from "./Profile";
import { FeatureFlagSettings } from "../Admin/FeatureFlagSettings";

export const ProfileSettings = () => {
  const email = localStorage.getItem("email");

  return (
    <Flex direction="column" minH="80vh" minW="135vh" maxH="100vh" overflow="auto">
      <Flex
        justify="space-between"
        align="center"
        p={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Heading size="xs" fontWeight="medium">
          Profile Settings
        </Heading>
      </Flex>
      <VStack spacing={8} align="stretch" width="100%" p={4}>
        <Flex direction="column" width="100%">
          <Profile />
        </Flex>
        {email && email.endsWith("fractalriver.com") && (
          <>
            <Divider />
            <FeatureFlagSettings />
          </>
        )}
      </VStack>
    </Flex>
  );
};
