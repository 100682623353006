import {
  Button,
  Center,
  CenterProps,
  HStack,
  Icon,
  Square,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FiUploadCloud } from "react-icons/fi";

export const Dropzone = ({
  onFileSelect,
  selectedFileName,
  ...props
}: CenterProps & {
  onFileSelect: (file: File) => void;
  selectedFileName: string | null;
}) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onFileSelect(file);
    }
  };

  return (
    <Center borderWidth="1px" borderRadius="lg" px="6" py="4" {...props}>
      <VStack spacing="3">
        <Square size="10" bg="bg.subtle" borderRadius="lg">
          <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
        </Square>
        <VStack spacing="1">
          <HStack spacing="1" whiteSpace="nowrap">
            <Button as="label" variant="text" colorScheme="brand" size="sm">
              Click to upload
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </HStack>
          <Text textStyle="xs" color="fg.muted">
            PDF, CSV or JSON up to 512MB
          </Text>
          {selectedFileName && (
            <Text textStyle="sm" color="fg.muted">
              Selected file: {selectedFileName}
            </Text>
          )}
        </VStack>
      </VStack>
    </Center>
  );
};
