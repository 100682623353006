import { ChakraProvider } from "@chakra-ui/react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { Admin } from "./pages/Admin";
import { Alerts } from "./pages/Alerts";
import { Analytics } from "./pages/Analytics";
import { Bot } from "./pages/Bot";
import { FileUpload } from "./pages/FileUpload";
import { Home } from "./pages/Home";
import { Integrations } from "./pages/Integrations";
import { Login } from "./pages/Login";
import { Settings } from "./pages/Settings";
import { myTheme } from "./theme";
import { FeatureFlagsProvider } from "./hooks/useFeatureFlags";

// Segment tracking
declare global {
  interface Window {
    analytics: any;
  }
}

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const token = localStorage.getItem("access_token");
  return token ? <>{children}</> : <Navigate to="/login" replace />;
};

function App() {
  return (
    <ChakraProvider theme={myTheme}>
      <FeatureFlagsProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="/home"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path="/integrations"
              element={
                <ProtectedRoute>
                  <Integrations />
                </ProtectedRoute>
              }
            />
            <Route
              path="/upload"
              element={
                <ProtectedRoute>
                  <FileUpload />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Analytics />
                </ProtectedRoute>
              }
            />
            <Route
              path="/bots/:id/:mode?/:assistant_id?"
              element={
                <ProtectedRoute>
                  <Bot />
                </ProtectedRoute>
              }
            />
            <Route
              path="/alerts"
              element={
                <ProtectedRoute>
                  <Alerts />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </FeatureFlagsProvider>
    </ChakraProvider>
  );
}

export default App;
