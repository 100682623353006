import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useListBotsFiles = (bot_id: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [files, setFiles] = useState<any[]>([]); 
  const toast = useToast();
  const listBotsFiles = async () => {
    if (files.length > 0) {
      return files;
    }
    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token || token === "") {
        throw new Error("Session expired");
      }
      const response = await fetch(
        `${API_URL}/api/v1/bots/bots/${bot_id}/files?token=${token}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log("response");
      if (!response.ok) {
        throw new Error("Failed to get files");
      }
      const data = await response.json();
      setFiles(data); // Cache the data in local state
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to get files",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (files.length === 0) {
      listBotsFiles();
    }
  }, [files]);

  return { loading, error, files, listBotsFiles };
};
