import { Flex } from "@chakra-ui/react";
import { KnowledgeBaseTable } from "../components/KnowledgeBase/KnowledgeBaseTable";
import { SideBar } from "../components/Sidebar/SideBar";
export const FileUpload = () => {
  return (
    <Flex direction="row" minH="100vh">
      <Flex flex="1">
        <SideBar />
      </Flex>
      <Flex flex="4" justify="center">
        <KnowledgeBaseTable bot_id="" />
      </Flex>
    </Flex>
  );
};
