export const supportedFileTypes = [
  { extension: ".c", mimeType: "text/x-c" },
  { extension: ".cpp", mimeType: "text/x-c++" },
  { extension: ".cs", mimeType: "text/x-csharp" },
  { extension: ".css", mimeType: "text/css" },
  { extension: ".doc", mimeType: "application/msword" },
  {
    extension: ".docx",
    mimeType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { extension: ".go", mimeType: "text/x-golang" },
  { extension: ".html", mimeType: "text/html" },
  { extension: ".java", mimeType: "text/x-java" },
  { extension: ".js", mimeType: "text/javascript" },
  { extension: ".json", mimeType: "application/json" },
  { extension: ".md", mimeType: "text/markdown" },
  { extension: ".pdf", mimeType: "application/pdf" },
  { extension: ".php", mimeType: "text/x-php" },
  {
    extension: ".pptx",
    mimeType:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  { extension: ".py", mimeType: "text/x-python" },
  { extension: ".rb", mimeType: "text/x-ruby" },
  { extension: ".sh", mimeType: "application/x-sh" },
  { extension: ".tex", mimeType: "text/x-tex" },
  { extension: ".ts", mimeType: "application/typescript" },
  { extension: ".txt", mimeType: "text/plain" },
];
