import { Flex } from "@chakra-ui/react";
import { AlertsHome } from "../components/Alerts/AlertsHome";
import { SideBar } from "../components/Sidebar/SideBar";

export const Alerts = () => {
  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar />
      </Flex>
      <Flex flex="4" justify="center">
        <AlertsHome />
      </Flex>
    </Flex>
  );
};
