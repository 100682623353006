import React from 'react';
import { Text, Switch, Flex, Box, BoxProps, Stack, Heading } from '@chakra-ui/react';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

export const FeatureFlagSettings: React.FC<BoxProps> = (props) => {
  const { flags, toggleFeatureFlag } = useFeatureFlags();

  if (!flags) return null;

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing="6"
      align="flex-start"
      width="full"
      {...props}
    >
      <Box flex="1">
        <Heading size="xs" fontWeight="medium">
          Feature Flag Settings
        </Heading>
        <Text mt="1" fontSize="sm" color="fg.muted">
          Toggle feature flags for testing purposes.
        </Text>
      </Box>
      <Box
        bg="bg.surface"
        boxShadow="sm"
        borderRadius="lg"
        flex="1"
        width="full"
      >
        <Stack
          spacing="5"
          px={{ base: "4", md: "6" }}
          py={{ base: "5", md: "6" }}
        >
          <Flex justifyContent="space-between" width="100%">
            <Text>Show Dashboard</Text>
            <Switch
              isChecked={flags.showDashboard}
              onChange={() => toggleFeatureFlag('showDashboard')}
            />
          </Flex>
          <Flex justifyContent="space-between" width="100%">
            <Text>Show Alerts</Text>
            <Switch
              isChecked={flags.showAlerts}
              onChange={() => toggleFeatureFlag('showAlerts')}
            />
          </Flex>
          <Flex justifyContent="space-between" width="100%">
            <Text>Show Bot Overview</Text>
            <Switch
              isChecked={flags.showBotOverview}
              onChange={() => toggleFeatureFlag('showBotOverview')}
            />
          </Flex>
          <Flex justifyContent="space-between" width="100%">
            <Text>Show Bot Integrations</Text>
            <Switch
              isChecked={flags.showBotIntegrations}
              onChange={() => toggleFeatureFlag('showBotIntegrations')}
            />
          </Flex>
          <Flex justifyContent="space-between" width="100%">
            <Text>Show Bot Knowledge Base</Text>
            <Switch
              isChecked={flags.showBotKnowledgeBase}
              onChange={() => toggleFeatureFlag('showBotKnowledgeBase')}
            />
          </Flex>
          <Flex justifyContent="space-between" width="100%">
            <Text>Show Bot Conversations</Text>
            <Switch
              isChecked={flags.showBotConversations}
              onChange={() => toggleFeatureFlag('showBotConversations')}
            />
          </Flex>
        </Stack>
      </Box>
    </Stack>
  );
};
