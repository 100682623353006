import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { BotVersion } from "../../client/types";
import { useCreateBotVersion } from "../../hooks/useCreateBotVersion";
import { useGetBotVersion } from "../../hooks/useGetBotVersion";
import { useListBotsVersions } from "../../hooks/useListBotsVersions";

export const NewVersion = ({
  botId,
  onNewBotVersion,
}: {
  botId: string;
  onNewBotVersion: (bot: BotVersion) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const { createBotVersion, loading } = useCreateBotVersion();

  // Fetch the list of bot versions
  const { botVersions } = useListBotsVersions(botId);

  // State to manage form inputs and selected version
  const [versionName, setVersionName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedVersionId, setSelectedVersionId] = useState<string | null>(
    null
  );
  const [selectedVersionData, setSelectedVersionData] = useState<any>(null);
  const [useDefault, setUseDefault] = useState(true); // State for using default values

  const { getBotVersion } = useGetBotVersion(botId, selectedVersionId ?? "");

  // Fetch the selected version's data when selectedVersionId changes
  useEffect(() => {
    const fetchVersionData = async () => {
      if (selectedVersionId && !useDefault) {
        try {
          const versionData = await getBotVersion();
          setSelectedVersionData(versionData);
        } catch (error) {
          console.error("Error fetching version:", error);
        }
      }
    };
    fetchVersionData();
  }, [selectedVersionId, useDefault, getBotVersion]);

  const handleSave = async () => {
    try {
      // Create the new bot version with either default instructions or copied instructions
      const defaultVersion = botVersions.find((v) => v.is_default);
      const newBotVersion = await createBotVersion({
        bot_id: botId,
        name: versionName,
        description: description,
        is_default: false,
        instructions: useDefault
          ? defaultVersion?.instructions
          : selectedVersionData.instructions,
      });

      // Pass the new bot version to the parent component
      onNewBotVersion(newBotVersion);

      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error creating version:", error);
    }
  };

  const handleDefaultCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseDefault(e.target.checked);
    if (e.target.checked) {
      setSelectedVersionId(null); // Deselect any version if default is chosen
    }
  };

  return (
    <>
      <Button onClick={onOpen}>New Version</Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create new version</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Version name</FormLabel>
              <Input
                ref={initialRef}
                value={versionName}
                placeholder="1.0.0"
                onChange={(e) => setVersionName(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Input
                value={description}
                placeholder="This is a new version"
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>Use engine prompt</FormLabel>
              <Flex align="center" mt={2}>
                <Switch
                  colorScheme="purple"
                  isChecked={useDefault}
                  onChange={() => setUseDefault(!useDefault)}
                />
              </Flex>
            </FormControl>

            {!useDefault && (
              <FormControl mt={4}>
                <FormLabel>Based on</FormLabel>
                <Select
                  placeholder="Use default"
                  value={selectedVersionId || ""}
                  onChange={(e) => setSelectedVersionId(e.target.value)}
                >
                  {botVersions.map((botVersion) => (
                    <option key={botVersion.id} value={botVersion.id}>
                      {botVersion.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              colorScheme="brand"
              variant="outline"
              isDisabled={loading}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              colorScheme="brand"
              onClick={handleSave}
              isDisabled={
                loading ||
                !versionName ||
                !description ||
                (!useDefault && !selectedVersionId)
              }
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
