import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { createBotWithVersion } from "../client/types";
import { API_URL } from "../utils/constants";

export const useCreateBot = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const token = localStorage.getItem("access_token") || "";

  const createBot = async (botData: createBotWithVersion) => {
    setLoading(true);
    setError(null);

    try {
      // Create payload with bot version
      const botVersionData: createBotWithVersion = {
        ...botData,
        description: "Default version",
        is_default: true,
        instructions: botData.instructions,
      };

      const botPayload = {
        bot: {
          organization_id: botData.organization_id,
          engine_id: botData.engine_id,
          name: botData.name,
          avatar: botData.avatar,
        },
        bot_version: botVersionData,
      };

      const createResponse = await fetch(`${API_URL}/api/v1/bots/bots`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(botPayload),
      });

      const data = await createResponse.json();
      if (!createResponse.ok) {
        throw new Error(data.message || "Failed to create bot");
      }

      toast({
        title: "Bot created.",
        description: "The bot has been successfully created.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Bot creation failed.",
        description: "Failed to create bot. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { createBot, error, loading };
};
