import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BotVersion } from "../client/types";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useGetBotVersion = (botId: string, botVersionId: string) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();

  const token = loadToken() || ""; // Load token from local storage

  // Rename state to avoid conflict with the BotVersion type
  const [fetchedBotVersion, setFetchedBotVersion] = useState<BotVersion | null>(
    null
  );

  const getBotVersion = async () => {
    if (fetchedBotVersion) {
      // Return cached bot version if it's already fetched
      return fetchedBotVersion;
    }
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${API_URL}/api/v1/bots/bots/${botId}/versions/${botVersionId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch bot version");
      }

      const data = await response.json();
      setFetchedBotVersion(data);

      return data;
    } catch (error: any) {
      setError(error.message || "An error occurred");
      toast({
        title: "Error fetching bot version",
        description: error.message || "An error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Fetch the bot version when component mounts or when botVersionId or botId changes
  useEffect(() => {
    if (botId && botVersionId) {
      getBotVersion();
    }
  }, [botId, botVersionId]); // Add dependencies to ensure proper refetching

  return { getBotVersion, fetchedBotVersion, loading, error };
};
