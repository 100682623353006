const getApiUrl = () => {
  if (window.location.hostname === "app.chatworks.io") {
    return "https://api.chatworks.io";
  } else if (window.location.hostname === "staging.app.chatworks.io") {
    return "https://staging.api.chatworks.io";
  } else {
    return "https://staging.api.chatworks.io";
  }
};
export const API_URL = getApiUrl();

export const APP_VERSION = "0.14.0";
