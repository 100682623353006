import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";
export const useListBots = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [bots, setBots] = useState<any[]>([]); // Local state to store fetched bots
  const toast = useToast();

  // FIXME - hardcoded organization_id for now, create a route to get the organization_id
  const organization_id = "fractalriver";

  const listBots = async () => {
    if (bots.length > 0) {
      // If bots are already loaded, no need to fetch again
      return bots;
    }

    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token) {
        throw new Error("Session expired");
      }

      const response = await fetch(
        `${API_URL}/api/v1/bots/bots/?organization_id=${organization_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          setError("No bots found");
          return [];
        }
        throw new Error("Failed to get bots");
      }

      const data = await response.json();
      setBots(data); // Cache the data in local state
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to get bots",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      window.location.reload();
    } finally {
      setLoading(false);
    }
  };

  // Optional useEffect to automatically fetch bots if not loaded yet
  useEffect(() => {
    if (bots.length === 0) {
      listBots(); // Fetch bots if not already cached
    }
  }, []); // Runs only once when the component mounts

  return { listBots, error, loading, bots }; // Expose bots for access in the component
};
