import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";
import { createBotVersion } from "../client/types";


export const useCreateBotVersion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const token = localStorage.getItem("access_token") || "";

  const createBotVersion = async (botData: createBotVersion) => {
    setLoading(true);
    setError(null);

    try {
      
      const response = await fetch(`${API_URL}/api/v1/bots/bots/${botData.bot_id}/versions`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
        body: JSON.stringify(botData),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to create bot");
      }

      toast({
        title: "Bot version created.",
        description: "The bot version has been successfully created.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      window.location.reload();
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Bot version creation failed.",
        description: "Failed to create bot version. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { createBotVersion, error, loading };
};
