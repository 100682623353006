import { Flex } from "@chakra-ui/react";
import { AdminSettings } from "../components/Admin/AdminSettings";
import { SideBar } from "../components/Sidebar/SideBar";

export const Admin = () => {
  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar />
      </Flex>
      <Flex flex="4" justify="center">
        <AdminSettings />
      </Flex>
    </Flex>
  );
};
