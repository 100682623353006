import IFrame from "react-iframe";

export const BotsPlayground = () => {
  return (
    <IFrame
      url="https://cw-management-console-dev.4ftk1ume9gu76.us-east-1.cs.amazonlightsail.com/"
      width="100%"
      height="100%"
      id=""
      className=""
      display="block"
      overflow="scroll"
      position="relative"
    />
  );
};
