import { LinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BotsList } from "../../client/types";
export const MyBotsTable = ({
  bots,
  ...props
}: { bots: BotsList[] } & TableProps) => {
  const navigate = useNavigate();

  const goToBotChat = (id: string) => {
    const url = `https://cw-chat-demo-app.4ftk1ume9gu76.us-east-1.cs.amazonlightsail.com/?user_id=1a896247-a678-41bf-b2c2-8bcc7a1b320d&bot_id=${id}`;
    window.open(url, "_blank");
  };
  const goToBot = (id: string) => {
     navigate(`/bots/${id}`);
   };

  if (bots.length === 0) {
    return (
      <Center height="100vh" width="70vw">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text ml={4}>Loading your bots...</Text>
      </Center>
    );
  }
  return (
    <Box width="70vw" maxH="80vh" overflowY="auto">
      <Table {...props}>
        <Thead position="sticky" top={0} bg="white" zIndex={1}>
          <Tr>
            <Th>
              <Text>Bot Name</Text>
            </Th>
            <Th>Default Version Name</Th>
            <Th>Default Version Description</Th>
            <Th>Engine</Th>
            <Th>Bot URL</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {bots.map((bot: BotsList) => (
            <Tr key={bot.id}>
              <Td>
                <Button variant="link" onClick={() => goToBot(bot.id)}>
                  {bot.name}
                </Button>
              </Td>
              <Td>
                <Text color="gray.600">
                  {bot.default_version?.name || "Default version"}
                </Text>
              </Td>
              <Td>
                <Text color="gray.600">
                  {bot.default_version?.description || "Default version"}
                </Text>
              </Td>
              <Td>
                <Text color="gray.600">
                  {bot.engine?.id || "Unknown Engine"}
                </Text>
              </Td>
              <Td>
                <Button
                  variant="link"
                  color="purple.500"
                  onClick={() => goToBotChat(bot.id)}
                >
                  <LinkIcon />
                </Button>
              </Td>
              <Td></Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
