import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useUploadBotFile } from "../../hooks/useUploadBotFile";
import { supportedFileTypes } from "../../utils/openaiSupportedFiles";
import { Dropzone } from "../Common/Dropzone";

export const UploadFileButton = ({
  bot_id,
  addFile,
}: {
  bot_id: string;
  addFile: (file: any) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { uploadFile, loading } = useUploadBotFile();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileDescription, setFileDescription] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);

  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
    setSelectedFileName(file.name);
  };

  const handleSave = async () => {
    if (selectedFile) {
      const uploadedFile = await uploadFile(
        bot_id,
        selectedFile,
        fileDescription
      );
      if (uploadedFile) {
        addFile(uploadedFile); // Add the uploaded file to the list
      }
      setSelectedFile(null);
      setFileDescription("");
      setSelectedFileName(null);
      onClose();
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    setFileDescription("");
    setSelectedFileName(null);
    onClose();
  };

  return (
    <>
      <Button onClick={onOpen}>Upload File</Button>
      <Modal isOpen={isOpen} onClose={handleCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select File</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Dropzone
              onFileSelect={handleFileSelect}
              selectedFileName={selectedFileName}
            />
            <Input
              mt={4}
              placeholder="Enter file description"
              value={fileDescription}
              onChange={(e) => setFileDescription(e.target.value)}
            />
          </ModalBody>
          <ModalFooter
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text textStyle="xs" color="fg.muted">
              {selectedFile &&
                selectedFile.size > 512 * 1024 * 1024 &&
                "File size exceeds 512MB"}
              {selectedFile && selectedFile.size === 0 && "File is empty"}
              {selectedFile &&
                selectedFile.type !==
                  supportedFileTypes.find(
                    (type) => selectedFile.type === type.mimeType
                  )?.mimeType &&
                "Unsupported file type"}
              {!selectedFile && ""}
            </Text>
            <div>
              <Button
                colorScheme="brand"
                mr={3}
                onClick={handleSave}
                isLoading={loading}
                isDisabled={
                  !selectedFile ||
                  !fileDescription ||
                  selectedFile.size > 512 * 1024 * 1024 ||
                  selectedFile.size === 0 ||
                  selectedFile.type !==
                    supportedFileTypes.find(
                      (type) => selectedFile.type === type.mimeType
                    )?.mimeType
                }
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                variant="outline"
                isDisabled={loading}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
