import { Flex } from "@chakra-ui/react";
import { ProfileSettings } from "../components/Profile/ProfileSettings";
import { SideBar } from "../components/Sidebar/SideBar";

export const Settings = () => {
  return (
    <Flex direction="row" minH="100vh" minW="50vw">
      <Flex flex="1">
        <SideBar />
      </Flex>
      <Flex flex="4" justify="center">
        <ProfileSettings />
      </Flex>
    </Flex>
  );
};
