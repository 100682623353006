import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";

export const useListEngines = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [engines, setEngines] = useState<any[]>([]); // Local state to store fetched engines
  const toast = useToast();

  const listEngines = async () => {
    if (engines.length > 0) {
      // If bots are already loaded, no need to fetch again
      return engines;
    }

    setLoading(true);
    setError(null);
    try {
      const token = loadToken() || "";
      if (!token) {
        throw new Error("Session expired");
      }
      const response = await fetch(`${API_URL}/api/v1/bots/engines`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          return [];
        }
        throw new Error("Failed to list engines");
      }

      const data = await response.json();
      setEngines(data); // Cache the data in local state
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to get engines",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (engines.length === 0) {
      listEngines(); // Fetch engines if not already cached
    }
  }, []); // Runs only once when the component mounts

  return { listEngines, error, loading, engines }; // Expose engines for access in the component
};
