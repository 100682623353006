import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { BotVersion as BotVersionType } from "../client/types";
import { API_URL } from "../utils/constants";

export const useDeleteBotVersion = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const [wasDeleted, setWasDeleted] = useState<boolean>(false); // Local state to store deletion status

  const deleteBotVersion = async (botId: string, version_id: string) => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("access_token") || "";
      const versionResponse = await fetch(
        `${API_URL}/api/v1/bots/bots/${botId}/versions/${version_id}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );
      const botVersion: BotVersionType = await versionResponse.json();
      if (!versionResponse.ok) {
        throw new Error("Failed to fetch bot version");
      }
      if (botVersion.is_default) {
        throw new Error("Cannot delete default bot version");
      }
      const response = await fetch(
        `${API_URL}/api/v1/bots/bots/${botId}/versions/${version_id}`,
        {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete bot version");
      }

      setWasDeleted(true);
      return true;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "Failed to delete bot",
        description: error.message || "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  return { deleteBotVersion, wasDeleted, loading, error };
};
