import React from "react";

interface ProfilePictureProps {
  fullName: string;
  organization: string;
}

const colorMap: { [key: string]: string } = {
  A: "#2E3B4E", // Dark Blue
  B: "#4A536B", // Gray Blue
  C: "#556B8B", // Steel Blue
  D: "#68748D", // Slate Blue
  E: "#7C8D9F", // Light Slate Gray
  F: "#455A64", // Blue Gray
  G: "#546E7A", // Blue Gray 2
  H: "#607D8B", // Blue Gray 3
  I: "#78909C", // Blue Gray 4
  J: "#8E9BA7", // Blue Gray 5
  K: "#1B263B", // Navy Blue
  L: "#34495E", // Dark Blue 2
  M: "#22313F", // Blue 2
  N: "#3A506B", // Blue Gray 6
  O: "#455A64", // Blue Gray 7
  P: "#546E7A", // Blue Gray 8
  Q: "#607D8B", // Blue Gray 9
  R: "#78909C", // Blue Gray 10
  S: "#8E9BA7", // Blue Gray 11
  T: "#2E3B4E", // Dark Blue 3
  U: "#4A536B", // Gray Blue 2
  V: "#556B8B", // Steel Blue 2
  W: "#68748D", // Slate Blue 2
  X: "#7C8D9F", // Light Slate Gray 2
  Y: "#1B263B", // Navy Blue 2
  Z: "#34495E", // Dark Blue 4
};

const getInitials = (name: string) => {
  // if its a single word, return the first two letters
  if (name.split(" ").length === 1) {
    return name.slice(0, 2).toUpperCase();
  }
  // else return the first letter of each word
  const names = name.split(" ");
  const initials = names.map((n) => n[0].toUpperCase()).join("");
  return initials;
};

const getColor = (organization: string) => {
  const firstChar = organization[0].toUpperCase();
  return colorMap[firstChar] || "#000000"; // Default to black if no match found
};

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  fullName,
  organization,
}) => {
  const initials = getInitials(fullName);
  const bgColor = getColor(organization);

  return (
    <div
      style={{
        width: "65px",
        height: "65px",
        borderRadius: "50%",
        backgroundColor: bgColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF",
        fontSize: "24px",
        fontWeight: "bold",
      }}
    >
      {initials}
    </div>
  );
};

export default ProfilePicture;
