import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Button,
  Container,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bot as BotType } from "../../client/types";
import { useDeleteBot } from "../../hooks/useDeleteBot";
import { useUpdateBot } from "../../hooks/useUpdateBot";
import { Dropzone } from "../Common/Dropzone";
import { on } from "events";

export const BotSetup = ({
  Bot,
  onChange,
}: {
  Bot: BotType;
  onChange: (newName: string) => void;
}) => {
  const botId = Bot.id;
  const [newBotName, setBotName] = useState(Bot.name);
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputBotId, setInputBotId] = useState("");
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { deleteBot, loading: deleteLoading } = useDeleteBot();
  const { updateBot, loading: updateLoading } = useUpdateBot();

  const handleDelete = async () => {
    if (inputBotId === "DELETE") {
      const success = await deleteBot(botId);
      if (success) {
        onClose();
        toast({
          title: "Bot deleted.",
          description: `The bot ${Bot.name} has been deleted successfully.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/home");
      }
    } else {
      toast({
        title: "Error",
        description: "Please enter 'DELETE' to confirm deletion.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleUpdateBot = async () => {
    if (newBotName) {
      await updateBot(botId, newBotName);
      onChange(newBotName);
      navigate("/bots/" + botId);
    } else {
      toast({
        title: "Error",
        description: "Failed to update bot name.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const goBack = () => {
    navigate(`/home`);
  };

  return (
    <Container py={{ base: "4", md: "8" }} minH="80vh" minW="135vh">
      <Stack spacing="5">
        <Stack
          spacing="4"
          direction={{ base: "column", sm: "row" }}
          justify="space-between"
        ></Stack>
        <Stack spacing="5" divider={<StackDivider />}>
          <FormControl id="name">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Bot Name</FormLabel>
              <Input
                maxW={{ md: "3xl" }}
                defaultValue={Bot.name}
                onChange={(e) => setBotName(e.target.value)}
              />
            </Stack>
          </FormControl>
          <FormControl id="engine">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Engine</FormLabel>
              <Input
                type="text"
                maxW={{ md: "3xl" }}
                defaultValue="Mark 4T"
                readOnly
                variant="ghost"
                textColor="gray"
              />
            </Stack>
          </FormControl>
          <FormControl id="picture">
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={{ base: "1.5", md: "8" }}
              justify="space-between"
            >
              <FormLabel variant="inline">Avatar</FormLabel>
              <Stack
                spacing={{ base: "3", md: "5" }}
                direction={{ base: "column", sm: "row" }}
                width="full"
                maxW={{ md: "3xl" }}
              >
                <Avatar size="lg" name={Bot.name} />
                <Dropzone
                  width="full"
                  onFileSelect={function (file: File): void {
                    throw new Error("Function not implemented.");
                  }}
                  selectedFileName={null}
                />
              </Stack>
            </Stack>
          </FormControl>

          <Flex direction="row-reverse">
            <Button
              mt="1"
              onClick={handleUpdateBot}
              isDisabled={
                newBotName === Bot.name || updateLoading ? true : false
              }
            >
              {updateLoading ? "Saving..." : "Save"}
            </Button>
            <Divider orientation="horizontal" variant="ghost" />
            <Button colorScheme="red" mt="1" onClick={onOpen}>
              Delete Bot
            </Button>
          </Flex>
        </Stack>
      </Stack>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Bot
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
              <FormControl mt={4}>
                <FormLabel>Enter DELETE to confirm</FormLabel>
                <Input
                  value={inputBotId}
                  onChange={(e) => setInputBotId(e.target.value)}
                />
              </FormControl>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} variant="ghost" isDisabled={deleteLoading}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3} isDisabled={deleteLoading}> 
                {deleteLoading ? "Deleting..." : "Delete"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Container>
  );
};
