import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Spinner,
  Stack,
  StackDivider,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetBot } from "../../hooks/useGetBot";
import { useGetBotVersion } from "../../hooks/useGetBotVersion";
import { useUpdateBotVersion } from "../../hooks/useUpdateBotVersion";

import { BotVersion } from "../../client/types";
import { MarkConfig } from "../Engines/MarkConfig";

export const VersionConfig = ({
  botId,
  version_id,
  isOpen,
  onClose,
  isDeleteMode = false,
  onUpdatedBotVersion,
}: {
  botId: string;
  version_id: string;
  isOpen: boolean;
  onClose: () => void;
  isDeleteMode?: boolean;
  onUpdatedBotVersion: (updatedBotVersion: any) => void;
}) => {
  const { bot } = useGetBot(botId);
  const { fetchedBotVersion: BotVersion, loading: getBotVersionLoading } =
    useGetBotVersion(botId, version_id);
  const { updateBotVersion, loading: updateBotVersionLoading } =
    useUpdateBotVersion();

  // State for version data
  const [versionName, setVersionName] = useState<string | undefined>("");
  const [versionDescription, setVersionDescription] = useState<
    string | undefined
  >("");
  const [instructions, setInstructions] = useState(
    BotVersion?.instructions || {
      role: "",
      general_context: "",
      ask: "",
      examples: [],
    }
  );
  const [isDefault, setIsDefault] = useState<boolean | undefined>(false);

  // State to track if any changes have been made
  const [isModified, setIsModified] = useState(false);

  const navigate = useNavigate();
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const toast = useToast();
  const { onOpen } = useDisclosure();

  // Set initial values when BotVersion is loaded
  useEffect(() => {
    if (BotVersion) {
      setVersionName(BotVersion.name);
      setVersionDescription(BotVersion.description);
      setInstructions(
        BotVersion.instructions || {
          role: "",
          general_context: "",
          ask: "",
          examples: [],
        }
      );
      setIsDefault(BotVersion.is_default);
    }
  }, [BotVersion]);

  useEffect(() => {
    // Check if the current values differ from the initial values
    if (BotVersion) {
      const hasChanges =
        versionName !== BotVersion.name ||
        versionDescription !== BotVersion.description ||
        JSON.stringify(instructions) !==
          JSON.stringify(BotVersion.instructions) ||
        isDefault !== BotVersion.is_default;

      setIsModified(hasChanges);
    }
  }, [versionName, versionDescription, instructions, isDefault, BotVersion]);

  const handleDelete = () => {
    onClose();
    toast({
      title: "Version deleted.",
      description: `The version ${versionName} has been deleted successfully.`,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate(`/home/`);
  };

  const handleSave = async () => {
    try {
      const updatedVersion: BotVersion = await updateBotVersion({
        bot_id: botId,
        version_id: version_id,
        name: versionName || "",
        description: versionDescription || "",
        is_default: isDefault || false,
        instructions,
      });
      onUpdatedBotVersion(updatedVersion);
      onClose();
    } catch (error) {
      toast({
        title: "Failed to save version.",
        description: "Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (!isOpen) return null;
  if (getBotVersionLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
        <Text ml={4}>Loading config for bot version...</Text>
      </Center>
    );
  } else {
    return (
      <Container py={{ base: "4", md: "8" }} width="100" overflowY="auto">
        {isDeleteMode ? (
          <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelRef}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Are you sure?
                </AlertDialogHeader>

                <AlertDialogBody>This action cannot be undone.</AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button colorScheme="red" onClick={handleDelete} ml={3}>
                    Delete
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        ) : (
          <Stack spacing="5">
            <Stack
              spacing="4"
              direction={{ base: "column", sm: "row" }}
              justify="space-between"
            >
              <Box>
                <Text textStyle="lg" fontWeight="medium">
                  Version Configuration
                </Text>
                <Text color="fg.muted" textStyle="sm">
                  Configure your bot's version details.
                </Text>
              </Box>
              <Stack direction="row">
                <Text color="fg.muted" textStyle="sm">
                  You are editing version {BotVersion?.version} of {bot?.name}
                </Text>
              </Stack>
            </Stack>
            <Divider />
            <Stack spacing="5" divider={<StackDivider />}>
              <FormControl id="name">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">Version Name</FormLabel>
                  <Input
                    maxW={{ md: "3xl" }}
                    value={versionName}
                    onChange={(e) => setVersionName(e.target.value)}
                  />
                </Stack>
              </FormControl>
              <FormControl id="description">
                <Stack
                  direction={{ base: "column", md: "row" }}
                  spacing={{ base: "1.5", md: "8" }}
                  justify="space-between"
                >
                  <FormLabel variant="inline">Version Description</FormLabel>
                  <Input
                    maxW={{ md: "3xl" }}
                    value={versionDescription}
                    onChange={(e) => setVersionDescription(e.target.value)}
                  />
                </Stack>
              </FormControl>
              <Box>
                <Text textStyle="lg" fontWeight="medium">
                  Instructions
                </Text>
                <Text color="fg.muted" textStyle="sm">
                  Configure the bot's knowledge base.
                </Text>
              </Box>
              <MarkConfig
                version={BotVersion}
                setInstructions={setInstructions}
              />

              <Stack direction="row" justify="space-between">
                <Box>
                  <Text textStyle="lg" fontWeight="bold" color="green.500">
                    Set as Default
                  </Text>
                  <Text color="fg.muted" textStyle="sm">
                    Make this version the default version for the bot.
                  </Text>
                </Box>
                <Switch
                  colorScheme="brand"
                  isChecked={isDefault}
                  onChange={() => setIsDefault(!isDefault)}
                />
              </Stack>
              <Button
                onClick={handleSave}
                colorScheme="brand"
                isDisabled={!isModified || updateBotVersionLoading}
                isLoading={updateBotVersionLoading}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        )}
      </Container>
    );
  }
};
