import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { API_URL } from "../utils/constants";
import { loadToken } from "../utils/validateToken";
export const useUploadBotFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const toast = useToast();
  const token = loadToken() || "";

  const uploadFile = async (
    bot_id: string,
    file: File,
    description: string
  ) => {
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_description", description);

    try {
      const response = await fetch(
        `${API_URL}/api/v1/bots/bots/${bot_id}/files?token=${token}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Authorization": "Bearer " + token,
          },
          body: formData,
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to upload file");
      }

      toast({
        title: "File uploaded.",
        description: "File uploaded successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      return data;
    } catch (error: any) {
      setError(error.message);
      toast({
        title: "File upload failed.",
        description: "Failed to upload file. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return { uploadFile, error, loading };
};
