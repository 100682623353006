const getDecodedToken = (token: string): any | null => {
  try {
    const payload = token.split(".")[1];
    return JSON.parse(atob(payload));
  } catch (error) {
    console.error("Invalid token format", error);
    return null;
  }
};

const isTokenExpired = (tokenData: any): boolean => {
  if (!tokenData || !tokenData.exp) {
    return true;
  }
  return tokenData.exp < Date.now() / 1000;
};

export const loadToken = (): string | null => {
  const token = localStorage.getItem("access_token");
  // check if token is in session storage if not, add it
  if (sessionStorage.getItem("access_token") === null && token !== null) {
    sessionStorage.setItem("access_token", token);
  }
  if (!token) {
    return null; // No token found, caller can decide what to do
  }

  const tokenData = getDecodedToken(token);

  if (isTokenExpired(tokenData)) {
    localStorage.removeItem("access_token");
    return null; // Session expired, caller can handle redirection
  }

  return token; // Token is valid, return it
};
