import { LinkIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Badge,
  Button,
  Center,
  HStack,
  IconButton,
  Spinner,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { useDeleteBotVersion } from "../../hooks/useDeleteBotVersion";
import { VersionConfig } from "./VersionConfig";

export const BotVersionsTable = (
  {
    id,
    isEditing,
    setIsEditing,
    selectedVersion, // Receive selectedVersion as a prop
    setSelectedVersion, // Receive setSelectedVersion as a prop
    botVersions,
    onUpdatedBotVersion,
  }: {
    id: string;
    isEditing: boolean;
    setIsEditing: (editing: boolean) => void;
    selectedVersion: string | null;
    setSelectedVersion: (version: string | null) => void; // Update prop type
    botVersions: any[];
    onUpdatedBotVersion: (updatedBotVersion: any) => void;
  },
  props: TableProps
) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chatModeState, setChatModeState] = useState<boolean>(false);
  const [botsTable, setBotsTable] = useState(botVersions ?? []);
  const [chatbot_url, setChatbot_url] = useState<string>("");
  const { deleteBotVersion, loading: deleteLoading } = useDeleteBotVersion();
  const toast = useToast();

  useEffect(() => {
    setBotsTable(botVersions);
  }, [botVersions]);

  const handleEdit = (version: string) => {
    setSelectedVersion(version); // Use setSelectedVersion from props
    setIsEditing(true);
  };

  const handleDelete = async (version: string) => {
    const success = await deleteBotVersion(id, version);
    if (success) {
      toast({
        title: "Version deleted.",
        description: `Version has been deleted successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setBotsTable((prev) => prev.filter((v) => v.id !== version));
    }
    setIsEditing(false);
    setSelectedVersion(null);
    onClose();
  };

  const handleOpenLink = (label: string, url: string) => {
    window.open(url, "_blank");
  };

  if (!botsTable || botsTable.length === 0) {
    return (
      <Center height="100vh">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
        <Text ml={4}>Loading versions for bot</Text>
      </Center>
    );
  }

  return (
    <>
      {isEditing && selectedVersion ? (
        <VersionConfig
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
          botId={id}
          version_id={selectedVersion}
          onUpdatedBotVersion={onUpdatedBotVersion}
        />
      ) : (
        <Table {...props} minW="100%">
          <Thead>
            <Tr>
              <Th>Number</Th>
              <Th>Name</Th>
              <Th>Description</Th>
              <Th>Created</Th>
              <Th>Updated</Th>
              <Th>Default</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {botsTable.map((source) => (
              <Tr key={source.id}>
                <Td>
                  <Text variant="muted" color="fg.muted">
                    {source.version}
                  </Text>
                </Td>
                <Td>
                  <Text variant="muted" color="fg.muted">
                    {source.name}
                  </Text>
                </Td>
                <Td>
                  <Text variant="muted" color="fg.muted">
                    {source.description}
                  </Text>
                </Td>
                <Td>
                  <Text color="fg.muted">
                    {new Date(source.inserted_at).toLocaleDateString()}
                  </Text>
                </Td>
                <Td>
                  <Text color="fg.muted">
                    {new Date(source.updated_at).toLocaleDateString()}
                  </Text>
                </Td>
                <Td>
                  <Badge
                    size="sm"
                    colorScheme={source.is_default ? "green" : "ghost"}
                  >
                    {source.is_default ? "Yes" : ""}
                  </Badge>
                </Td>
                <Td>
                  <HStack spacing="1">
                    <IconButton
                      icon={<FiEdit2 />}
                      variant="tertiary"
                      aria-label="Edit version"
                      onClick={() => handleEdit(source.id)}
                    />
                    <IconButton
                      icon={<LinkIcon />}
                      variant="tertiary"
                      color="purple.500"
                      aria-label="Open version"
                      onClick={() =>
                        handleOpenLink(
                          source.name,
                          `https://cw-chat-demo-app.4ftk1ume9gu76.us-east-1.cs.amazonlightsail.com/?user_id=1a896247-a678-41bf-b2c2-8bcc7a1b320d&assistant_id=${source.assistant_id}`
                        )
                      }
                    />
                    <Tooltip
                      label={
                        source.is_default
                          ? "Cannot delete default version"
                          : "Delete"
                      }
                    >
                      <IconButton
                        icon={<FiTrash2 />}
                        variant="tertiary"
                        aria-label="Delete version"
                        onClick={() => {
                          setSelectedVersion(source.id);
                          onOpen();
                        }}
                        isDisabled={source.is_default}
                      />
                    </Tooltip>
                  </HStack>
                  <AlertDialog
                    isOpen={isOpen}
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          Delete Version
                        </AlertDialogHeader>
                        <AlertDialogBody>
                          Are you sure you want to delete this version? This
                          action cannot be undone.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                          <Button
                            ref={cancelRef}
                            onClick={onClose}
                            isDisabled={deleteLoading}
                            variant="outline"
                          >
                            Cancel
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={() => handleDelete(selectedVersion!)}
                            ml={3}
                            isDisabled={deleteLoading}
                          >
                            Delete
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </>
  );
};
