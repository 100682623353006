import { Flex } from "@chakra-ui/react";
import { Usage } from "./Usage";

export const Dashboard = () => {
  return (
    <Flex direction="column" minH="100vh" minW="150vh" align="center">
      <Flex flex="1">
        <Usage />
      </Flex>
    </Flex>
  );
};
