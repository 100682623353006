import React, { createContext, useContext, useState, useEffect } from 'react';

interface FeatureFlags {
  showDashboard: boolean;
  showAlerts: boolean;
  showBotOverview: boolean;
  showBotIntegrations: boolean;
  showBotKnowledgeBase: boolean;
  showBotConversations: boolean;
}

interface FeatureFlagsContextType {
  flags: FeatureFlags | null;
  toggleFeatureFlag: (flagName: keyof FeatureFlags) => void;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType | null>(null);

const getDummyFeatureFlags = (): FeatureFlags => ({
  showDashboard: false,
  showAlerts: false,
  showBotOverview: false,
  showBotIntegrations: false,
  showBotKnowledgeBase: false,
  showBotConversations: false,
});

export const FeatureFlagsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags | null>(null);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        if (process.env.NODE_ENV === 'development') {
          // Use dummy data in development mode
          setFeatureFlags(getDummyFeatureFlags());
        } else {
          // Use actual API call in production
          const response = await fetch('/api/feature-flags');
          const data = await response.json();
          setFeatureFlags(data);
        }
      } catch (error) {
        console.error('Error fetching feature flags:', error);
        setFeatureFlags(getDummyFeatureFlags());
      }
    };

    fetchFeatureFlags();
  }, []);

  const toggleFeatureFlag = (flagName: keyof FeatureFlags) => {
    setFeatureFlags((prevFlags) => {
      if (!prevFlags) return prevFlags;
      return { ...prevFlags, [flagName]: !prevFlags[flagName] };
    });
  };

  const contextValue: FeatureFlagsContextType = {
    flags: featureFlags,
    toggleFeatureFlag,
  };

  return (
    <FeatureFlagsContext.Provider value={contextValue}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export const useFeatureFlags = (): FeatureFlagsContextType => {
  const context = useContext(FeatureFlagsContext);
  if (context === null) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }
  return context;
};
