import {
  Avatar,
  Badge,
  Box,
  Checkbox,
  HStack,
  Icon,
  IconButton,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { IoArrowDown } from "react-icons/io5";
import { sources } from "../../data/integrations";

export const IntegrationsSourcesTable = (props: TableProps) => (
  <Table {...props}>
    <Thead>
      <Tr>
        <Th>
          <HStack spacing="3">
            <Checkbox />
            <HStack spacing="1">
              <Text>Name</Text>
            </HStack>
          </HStack>
        </Th>
        <Th>Type</Th>
        <Th>Status</Th>

        <Th></Th>
      </Tr>
    </Thead>
    <Tbody>
      {sources.map((source) => (
        <Tr key={source.id}>
          <Td>
            <HStack spacing="3">
              <Checkbox />
              <Box>
                <Text fontWeight="medium">{source.name}</Text>
              </Box>
            </HStack>
          </Td>

          <Td>
            <Avatar name={source.name} src={source.avatarUrl} boxSize="10" />
          </Td>
          <Td>
            <Badge
              size="sm"
              colorScheme={source.status === "active" ? "green" : "red"}
            >
              {source.status}
            </Badge>
          </Td>

          <Td>
            <HStack spacing="1">
              <IconButton
                icon={<FiEdit2 />}
                variant="tertiary"
                aria-label="Edit source"
              />

              <IconButton
                icon={<FiTrash2 />}
                variant="tertiary"
                aria-label="Delete source"
              />
            </HStack>
          </Td>
        </Tr>
      ))}
    </Tbody>
  </Table>
);
