import { Box, Flex, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { File as FileType } from "../../client/types";
import { useListBotsFiles } from "../../hooks/useListBotsFiles";
import { KnowledgeBaseSourcesTable } from "./KnowledgeBaseSourcesTable";
import { UploadFileButton } from "./KnowledgeBaseUploadButton";

export const KnowledgeBaseTable = ({ bot_id }: { bot_id: string }) => {
  const { files: initialFiles, error } = useListBotsFiles(bot_id);
  const [files, setFiles] = useState<FileType[]>(initialFiles || []);

  useEffect(() => {
    if (initialFiles) {
      setFiles(initialFiles);
    }
  }, [initialFiles]);

  const addFile = (file: FileType) => {
    setFiles((prevFiles) => [...prevFiles, file]);
  };

  const removeFile = (fileId: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
  };

  return (
    <Flex direction="column" minH="80vh" minW="130vh">
      <Flex
        justify="space-between"
        align="center"
        p={4}
        borderBottom="1px solid #e2e8f0"
      >
        <Text fontSize="xl" fontWeight="bold">
          Knowledge Base
        </Text>
        <UploadFileButton bot_id={bot_id} addFile={addFile} />
      </Flex>

      <Flex flex="1" p={4}>
        <Box flex="1">
          {error ? (
            <Text color="red.500">
              Failed to load files. Please try again later.
            </Text>
          ) : files.length > 0 ? (
            <KnowledgeBaseSourcesTable files={files} removeFile={removeFile} />
          ) : (
            <Text>No files available. You can upload a new file.</Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};
